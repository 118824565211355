import React from 'react';
import { Tab, Box, FormControl, FormGroup, FormControlLabel, Switch, Paper, Button, Typography } from '@mui/material';
import CompartmentField from './CompartmentField';
import { useTranslation } from 'react-i18next';
import { TabContext, TabList } from '@mui/lab';
import useGlobal from 'global-state/store';
import DisplayPAInputs from './DisplayPAInputs';
import { UseFormReturn } from 'react-hook-form';
import { FormInputs } from './CompartmentValidationSchema';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DoneIcon from '@mui/icons-material/Done';
import WarningIcon from '@mui/icons-material/Warning';
import decimalsMask from './DecimalsMask';

export interface PAConvParams {
  measuredTav: {
    value: string;
    unit: string;
    precision: number;
  };
  tavTemperature: {
    value: string;
    unit: string;
    precision: number;
  };
  liquidTemperature: {
    value: string;
    unit: string;
    precision: number;
  };
}

interface AddOrRemoveTabsProps {
  rhform: UseFormReturn<FormInputs, any, undefined>,
  onTabChange: (event: React.SyntheticEvent, newValue: string) => void;
  volumeUnit: string;
  callSubmit: () => void;
  setTAVInputsDialogOpen: any;
}


const AddOrRemoveTabs: React.FC<AddOrRemoveTabsProps> = ({ 
  rhform, onTabChange, volumeUnit, callSubmit, setTAVInputsDialogOpen
}) => {
  const { t } = useTranslation();
  const [globalState, globalActions] = useGlobal();

  const {
    setValue,
    watch,
    control,
    formState: { errors },
  } = rhform;

  const handleSwitchClick = (event: React.ChangeEvent<HTMLInputElement>) => {

    if(event.target.checked) {
      if (globalState.networkState === 'offline') {
        globalActions.setSnackbarMessage({message: t('forms.need_network')});
        return;
      }
      setTAVInputsDialogOpen(true);
    } else {
      callSubmit();
    }
    setValue('volumeToAdd.isPureAlcohol', event.target.checked);
  };

  const isAddVolume = watch("volumeToAdd.isAddVolume");
  const isPureAlcohol = watch("volumeToAdd.isPureAlcohol");
  const tavInputs = watch("tavInputs");

  const tavInputsPresent = tavInputs.measuredTav.value ? true : false;

  return (
    <Box sx={{ width: '100%' }}>

      <FormControl component="fieldset" variant="standard">
        <FormGroup>
          <FormControlLabel
            control={
              <Switch checked={isPureAlcohol} 
                onChange={handleSwitchClick} 
                name="isAddOrRemovePASwitch" />}
            label={t('operation.is_pa_switch')}
            labelPlacement="start"
          />
        </FormGroup>
      </FormControl>

      {tavInputsPresent && (
        <Box sx={{display: 'flex', flexDirection: 'row',
          alignItems: 'center', justifyContent: 'center', gap: 2, mt: 2 }}>
          <DisplayPAInputs paInputs={tavInputs} />
          <Button 
            onClick={() => setTAVInputsDialogOpen(true)}
            variant="outlined"
            size="small" 
            color="primary">
            <ModeEditIcon sx={{fontSize: '18px' }} />
          </Button>
        </Box>
      )}

      <Paper 
        elevation={0} 
        sx={{ 
          px: 2,
          pt: 1, pb: 2, mt: 2,
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center'
        }}>

        <TabContext
          value={isAddVolume ? 'add' : 'remove'}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
            <TabList
              onChange={onTabChange}
              centered
            >
              <Tab label={t('operation.add_volume')} value="add" />
              <Tab label={t('operation.remove_volume')} value="remove" />
            </TabList>
          </Box>
          <CompartmentField
            label={isPureAlcohol ? t('operation.volume_AP', { unit: volumeUnit }) : t('operation.volume', { unit: volumeUnit })}
            name="volumeToAdd.value"
            inputMode="decimal"
            decimalScale={decimalsMask[volumeUnit as keyof typeof decimalsMask]}
            control={control}
            onBlur={callSubmit}
            error={errors.volumeToAdd?.value ? true : false}
            helperText={errors.volumeToAdd?.value?.message}
          />

        </TabContext>
        <Button 
          sx={{mt: 3, mb: 1}}
          variant="outlined"
          color="primary">
          <DoneIcon sx={{fontSize: '18px' }} />
        </Button>
      </Paper>
    </Box>
  );
};

export default AddOrRemoveTabs;