import * as XLSX from 'xlsx';
import { generateCellarSummarySheet } from 'utils/excelGenerators';
import { generateInventorySheetRDM } from './generateInventorySheetRDM';
import { generateTodayOperationsSheetDRM } from './generateTodayOperationsSheetDRM';

export const generateXLSXDRM = async (lastOpsAndTanks, globalState, t, cellarsRef) => {
  const { lastOps, tankDataMap } = lastOpsAndTanks;
  const volumeUnit = lastOps[0]?.form.volumeUnit || 'hL';

  // First worksheet (inventory)
  const ws = XLSX.utils.json_to_sheet([]);
  await generateInventorySheetRDM(ws, lastOps, tankDataMap, volumeUnit, globalState.extraOperationFields, t, cellarsRef);

  // Set print settings for first worksheet
  ws['!printSetup'] = { 
    orientation: 'landscape',
    fitToWidth: 1,
    fitToHeight: 1
  };

  // Add page margins
  ws['!margins'] = {
    left: 0.25,
    right: 0.25,
    top: 0.75,
    bottom: 0.75,
    header: 0.3,
    footer: 0.3
  };

  const cellarFilter1 = 'CUVES JOURNALIERES';
  const cellarFilter2 = 'CUVES MAUVAIS GOUT';

  // const cellarFilter1 = 'Chai 2';
  // const cellarFilter2 = 'Chai D';

  // Filter today's operations of cellar "CUVES JOURNALIERES"
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const todayOpsCJ = lastOps.filter(op => {
    const opDate = new Date(op.dateAndTime.seconds * 1000 + op.dateAndTime.nanoseconds / 1000000);
    return opDate >= today && op.cellarName === cellarFilter1;
  });

  // Filter today's operations of cellar "CUVES MAUVAIS GOUT"
  const todayOpsMG = lastOps.filter(op => {
    const opDate = new Date(op.dateAndTime.seconds * 1000 + op.dateAndTime.nanoseconds / 1000000);
    return opDate >= today && op.cellarName === cellarFilter2;
  });

  console.log(todayOpsMG)

  // Second worksheet (today's operations)
  const ws2 = XLSX.utils.json_to_sheet([]);
  const ws3 = XLSX.utils.json_to_sheet([]);

  generateTodayOperationsSheetDRM(ws2, todayOpsCJ, volumeUnit, t, 'DECLARATIONS PRODUCTION BACS JOURNALIERS');
  generateTodayOperationsSheetDRM(ws3, todayOpsMG, volumeUnit, t, 'DECLARATIONS PRODUCTION CUVES MAUVAIS GOUT');

  // Set print settings for other worksheets
  [ws2, ws3].forEach(sheet => {
    sheet['!printSetup'] = {
      orientation: 'landscape',
      fitToWidth: 1,
      fitToHeight: 1
    };
    sheet['!margins'] = {
      left: 0.25,
      right: 0.25,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3
    };
  });

  // Third worksheet (cellar summaries)
  const ws4 = XLSX.utils.json_to_sheet([]);
  generateCellarSummarySheet(ws4, lastOps, volumeUnit, t);

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, ws, 'Dernières opérations par cuve');
  XLSX.utils.book_append_sheet(workbook, ws2, 'Suivi Jour prod Bacs Compteurs');
  XLSX.utils.book_append_sheet(workbook, ws3, 'Suivi Jour prod MG');
  XLSX.utils.book_append_sheet(workbook, ws4, 'Résumé par chai');

  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const year = today.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;

  XLSX.writeFile(workbook, `inventaire-${formattedDate}.xls`);
};
