import * as XLSX from 'xlsx';

export const generateTodayOperationsSheetDRM = (ws2, todayOps, volumeUnit, t, title) => {
  // Add title row with merged cells
  const titleRow = [
    '',  // First column empty
    { t: 's', v: title, colspan: 8 },
    '', '', '', '', '', '', '', ''  // Empty cells that will be merged
  ];
  
  const todayHeaders = [
    t('daily_inventory.headers.last_op_date'),
    'Produit',
    'N° Bacs J',
    'MESURE NIVEAU en mm',
    'Vol. apparent EQUIVALENT EN L',
    'DEGRE apparent',
    'T° apparente',
    'DEGRE CORRIGE à 20°',
    'VOLUME LAV à 20°',
    'VOLUME LAP',
  ];

  // Add both rows to worksheet
  XLSX.utils.sheet_add_aoa(ws2, [titleRow, todayHeaders], { origin: 'A1' });

  // Add merge for title
  ws2['!merges'] = [
    { s: { r: 0, c: 1 }, e: { r: 0, c: 9 } } 
  ];

  const todayData = todayOps.map(op => {
    // Parse numeric values
    const height = op.form.results.height?.value || op.form.results.height || '';
    const volume = op.form.results.volume?.value || op.form.results.volume || '';
    const measuredTav = op.form.tavInputs.measuredTav?.value || '';
    const temperature = op.form.tavInputs.tavTemperature?.value || '';
    const abvAt20c = op.form.paConversionResults?.abvAt20c?.value || op.form.results.volumeConvResult?.abvAt20c?.value || '';
    const volumeAtTemp = op.form.paConversionResults?.volumeAtTemp?.value || op.form.results.volumeConvResult?.volumeAtTemp?.value || '';
    const pureAlcoholVolume = op.form.paConversionResults?.pureAlcoholVolumeAt20c?.value || op.form.results.volumeConvResult?.pureAlcoholVolumeAt20c?.value || '';

    return [
      new Date(op.dateAndTime.seconds * 1000 + op.dateAndTime.nanoseconds / 1000000)
        .toLocaleDateString('fr-FR'),
      op.form.extraFields?.find(f => f.name === 'nature-des-produits')?.value || '',
      op.tankName || op.tankNumber,
      height ? parseFloat(height) : '',
      volume ? parseFloat(volume) : '',
      measuredTav ? parseFloat(measuredTav) : '',
      temperature ? parseFloat(temperature) : '',
      abvAt20c ? parseFloat(abvAt20c) : '',
      volumeAtTemp ? parseFloat(volumeAtTemp) : '',
      pureAlcoholVolume ? parseFloat(pureAlcoholVolume) : '',
    ];
  });

  // Update origin to A3 since we now have a title row
  XLSX.utils.sheet_add_json(ws2, todayData, { skipHeader: true, origin: 'A3' });

  ws2['!cols'] = [
    { wch: 30 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
  ];

  // Set row heights
  ws2['!rows'] = [];
  ws2['!rows'][0] = { hpt: 30 };  // Title row height
  ws2['!rows'][1] = { hpt: 30 };  // Header row height
};
