import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import { getAnalytics } from 'firebase/analytics';
import useGlobal from 'global-state/store';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import { useAuth } from 'firebaseHooks/AuthContext';
import saveOperationToFirestore from './firestoreSaveOperation';
import FormatColorResetIcon from '@mui/icons-material/FormatColorReset';
import { initialFormValues } from './compartment/utils';

export default function EmptyContainerButton(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const db = useFirestore();
  const { currentUser: user } = useAuth();
  const analytics = getAnalytics();
  const [globalState, globalActions] = useGlobal();
  const [emptyOpen, setEmptyOpen] = useState(false);
  
  const {
    certificatePath,
    tankNumber,
    cellar,
    getForm,
    tankName,
    cellarName,
    certificateName,
  } = props;

  const handleEmptyContainer = () => {
    // Create an empty form state using initialFormValues
    const emptyForm = {
      version: 2,
      nominalVolume: getForm().nominalVolume,
      volumeUnit: getForm().volumeUnit,
      ...initialFormValues(getForm().volumeUnit, () => 'add'),
      // Override specific values to be 0
      height: {
        value: '0',
        unit: 'mm',
        precision: 0,
      },
      volume: {
        value: '0',
        unit: getForm().volumeUnit,
        precision: 4,
      },
      results: {
        volume: { value: '0', unit: getForm().volumeUnit, precision: 4 },
        height: { value: '0', unit: 'mm', precision: 0 },
      }
    };

    // Save the empty state to Firestore
    saveOperationToFirestore(
      db,
      globalState.clientPath,
      analytics,
      user,
      tankNumber,
      certificatePath,
      cellar,
      tankName,
      cellarName,
      certificateName,
      emptyForm,
      {},
      globalState.volumeConversionMethod,
    );
    setEmptyOpen(false);
    globalActions.setSnackbarMessage({
      message: globalState.networkState !== 'offline'
        ? t('operation.save_success_text')
        : t('operation.offline_save_warning'),
      severity: 'success'
    });
    navigate('/');
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        onClick={() => setEmptyOpen(true)}
        endIcon={<FormatColorResetIcon />}
      >
        {t('operation.empty_container')}
      </Button>

      <Dialog
        open={emptyOpen}
        onClose={() => setEmptyOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('operation.empty_container_title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('operation.empty_container_text')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size="large"
            variant="outlined"
            onClick={() => setEmptyOpen(false)}
            color="secondary"
          >
            {t('no')}
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={handleEmptyContainer}
            color="primary"
          >
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
} 