import * as XLSX from 'xlsx';
import { roundNoFixed } from 'components/utils';
import { unit } from 'mathjs';
import { doc, getDoc } from 'firebase/firestore';

export const generateInventorySheetRDM = async (ws, lastOps, tankDataMap, volumeUnit, extraOperationFields, t, cellarsRef) => {
  // Define styles
  const boldStyle = { font: { bold: true, name: 'Arial' } };
  const boldCenteredStyle = { 
    font: { bold: true, name: 'Arial' },
    alignment: { horizontal: 'center', vertical: 'center' }
  };

  // Keep the first 9 lines unchanged
  const firstLines = [
    ['', { t: 's', v: 'Distillerie Rivière du mat', colspan: 2, s: boldCenteredStyle }, '', 
      { t: 's', v: 'ENREGISTREMENT', colspan: 2, s: boldCenteredStyle }, '', 'ENR 032'],
    ['', '', '', { t: 's', v: 'Inventaire journalier des stocks alcools', colspan: 2, s: boldCenteredStyle }, '', 'version 2'],
    ['', '', '', '', '', 'Date modication 10/05/2021'],
    [],
    ['', '', '', '', '', 'page : 1/1'],
    [],
    ['', 'Date :', new Date().toLocaleDateString('fr-FR'), '', '', '', ''],
    ['', { t: 's', v: 'ATTENTION : LES CUVES VIDES NE SONT PAS DEGAZEES', colspan: 6, s: boldCenteredStyle }, ''],
    ['', 
      { t: 's', v: 'N° Citerne', s: boldStyle },
      { t: 's', v: 'Volume en L', s: boldStyle },
      { t: 's', v: 'Degré', s: boldStyle },
      { t: 's', v: 'Alcool pur en lap', s: boldStyle },
      { t: 's', v: 'Nature des produits', s: boldStyle },
      { t: 's', v: 'Capacité utile en L', s: boldStyle },
      { t: 's', v: 'CUVE DISPONIBLE', s: boldStyle }
    ],
  ];

  // Add merged cells configuration
  ws['!merges'] = [
    { s: { r: 0, c: 1 }, e: { r: 4, c: 2 } },  // Distillerie Rivière du mat
    { s: { r: 0, c: 3 }, e: { r: 0, c: 4 } },  // ENREGISTREMENT
    { s: { r: 1, c: 3 }, e: { r: 4, c: 4 } },  // Inventaire journalier
    { s: { r: 7, c: 1 }, e: { r: 7, c: 6 } },  // ATTENTION message
  ];

  XLSX.utils.sheet_add_aoa(ws, firstLines, { origin: 'A1' });

  const columnWidths = [
    { wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 30 }, { wch: 30 }
  ];
  ws['!cols'] = columnWidths;

  // Add row heights
  ws['!rows'] = [];
  // Set default height for all rows
  for(let i = 0; i < 50; i++) {  // Adjust 50 to cover all your rows
    ws['!rows'][i] = { hpt: 15 }; 
  }
  // Set specific height for the header row (row 9 - N° Citerne, etc.)
  ws['!rows'][8] = { hpt: 30 };  // Make this row taller (30 points)

  const dataPromises = Object.entries(tankDataMap).map(async ([tankId, tankData]) => {
    const operation = lastOps.find(op => op.tankNumber === tankId);
    const { compartment } = tankData.fullActiveCert || {};
    const maxVolume = compartment ? roundNoFixed(unit(compartment.maxVolume, 'L').toNumber(volumeUnit), 4) : '';
    
    let cellarName = '';
    let volume = 'VIDE';
    let abv = '';
    let pureAlcohol = '';
    let productType = '';
    let availability = '';
    
    if (operation) {
      cellarName = operation.cellarName || operation.cellar;
      const height = operation.form.results.height?.value || operation.form.results.height;
      const realVolume = operation.form.results.volume?.value || operation.form.results.volume;
      volume = (height === '0' || realVolume === '0' || realVolume === 0) ? 'VIDE' 
        : (height === '0' && realVolume !== '0') ? `0 ↔ ${realVolume}` 
          : realVolume ? parseFloat(realVolume) : '';
      
      // Parse numeric values
      const rawAbv = operation.form.paConversionResults?.abvAt20c?.value || operation.form.results.volumeConvResult?.abvAt20c?.value || '';
      abv = rawAbv ? parseFloat(rawAbv) : '';
      
      const rawPureAlcohol = operation.form.paConversionResults?.pureAlcoholVolumeAt20c?.value || operation.form.results.volumeConvResult?.pureAlcoholVolumeAt20c?.value || '';
      pureAlcohol = rawPureAlcohol ? parseFloat(rawPureAlcohol) : '';
      
      productType = operation.form.extraFields?.find(f => f.name === 'nature-des-produits')?.value || '';
      availability = operation.form.extraFields?.find(f => f.name === 'cuve-disponible')?.value || '';
    } else {
      // Fetch cellar data only when there's no operation
      const cellarRef = doc(cellarsRef, tankData.cellar);
      const cellarDoc = await getDoc(cellarRef);
      cellarName = cellarDoc.exists() ? cellarDoc.data().name : '';
    }
    
    // Parse maxVolume to number if it exists
    const parsedMaxVolume = maxVolume ? parseFloat(maxVolume) : '';

    return {
      cellar: tankData.cellar,
      row: [
        { t: 's', v: cellarName, s: boldCenteredStyle },
        tankData.name || tankId,
        volume,
        abv,
        pureAlcohol,
        productType,
        parsedMaxVolume,
        availability
      ]
    };
  });

  const data = await Promise.all(dataPromises);
  
  // Group by cellar and add "Sous-total" rows
  const groupedData = [];
  let currentCellar = '';
  let subtotal = [
    '', { t: 's', v: 'Sous-total', s: boldCenteredStyle }, '', '', 0, '', '', ''
  ];
  
  let cellarMerges = [];
  let cellarStartRow = 9;  // Starting from row 10 (A10)
  let currentGroupSize = 0;  // Track number of rows in current cellar group
  let totalPureAlcohol = 0;
  data.forEach(({ cellar, row }, index) => {
    if (cellar !== currentCellar) {
      if (currentCellar !== '') {
        // Add merge range for previous cellar group (excluding subtotal row)
        cellarMerges.push({
          s: { r: cellarStartRow, c: 0 },
          e: { r: cellarStartRow + currentGroupSize - 1, c: 0 }  // -1 to exclude subtotal row
        });
        groupedData.push(subtotal);
        subtotal = ['', 'Sous-total', '', '', 0, '', '', ''];
        cellarStartRow = cellarStartRow + currentGroupSize + 1; // +1 for the subtotal row
        currentGroupSize = 0;
      }
      currentCellar = cellar;
    }
    groupedData.push(row);
    currentGroupSize++;
    
    // Add pure alcohol value to subtotal and total if it exists and is a number
    const pureAlcoholValue = parseFloat(row[4]);
    if (!isNaN(pureAlcoholValue)) {
      subtotal[4] = roundNoFixed(subtotal[4] + pureAlcoholValue, 4);
      totalPureAlcohol = roundNoFixed(totalPureAlcohol + pureAlcoholValue, 4);
    }
  });
  
  // Handle the last cellar group
  if (data.length > 0) {
    cellarMerges.push({
      s: { r: cellarStartRow, c: 0 },
      e: { r: cellarStartRow + currentGroupSize - 1, c: 0 }  // -1 to exclude subtotal row
    });
    groupedData.push(subtotal);
    
    // Add the final total row
    groupedData.push([
      '',
      { t: 's', v: 'Total', s: boldCenteredStyle },
      '',
      '',
      totalPureAlcohol,
      '',
      '',
      ''
    ]);

    groupedData.push(['', 'AT: alcool de tetes; RA: rhum agricole; RT: rhum traditionnel; AN: alcool neutre; F: Flegmes. Edv/ Eau de vie de canne;RL:rhum léger', '', '', '', '', '', '']);
    groupedData.push(['', 'P/D : propre et disponible', '', '', '', '', '', '']);
  }

  XLSX.utils.sheet_add_json(ws, groupedData, { skipHeader: true, origin: 'A10' });
  
  // Add cellar merges to existing merges
  ws['!merges'] = [
    ...ws['!merges'],
    ...cellarMerges
  ];
};
