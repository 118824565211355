import React, { useState } from 'react';
import useGlobal from 'global-state/store';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import {
  collection, doc, getDoc, getDocs, limit, orderBy, query, where,
} from 'firebase/firestore';
import DownloadIcon from '@mui/icons-material/Download';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import { generateTodayOperationsSheet, generateCellarSummarySheet, generateInventorySheet } from 'utils/excelGenerators';
import { generateXLSXDRM } from 'utils/riviereDuMat/generateXLSX';

export default function DailyInventory() {
  const firestore = useFirestore();
  const [globalState, globalActions] = useGlobal();
  const { activeOrganization } = globalState;
  const { t } = useTranslation();

  const tanksRef = collection(firestore, `organizations/${activeOrganization}/apps/digitank-gauged-containers/tanks`);
  const operationsRef = collection(firestore, `organizations/${activeOrganization}/apps/`
    + 'digitank-gauged-containers/operations');
  const cellarsRef = collection(firestore, `organizations/${activeOrganization}/apps/digitank-gauged-containers/cellars`);
  const [loading, setLoading] = useState(false);

  const isNewCertificateModel = (certifData) => Boolean(certifData.reference);

  const fetchTankData = async (tanksSnapshot) => {
    const tankDataMap = {};

    const tankPromises = tanksSnapshot.docs.map(async (dct) => {
      const tankData = dct.data();
      const activeCertificateId = tankData.activeCertificate;

      if (activeCertificateId) {
        const certifRef = doc(firestore, `organizations/${activeOrganization}/apps/`
          + `digitank-gauged-containers/tanks/${dct.id}/gaugeCertificates/${activeCertificateId}`);
        const certificateDoc = await getDoc(certifRef);

        const certificateData = certificateDoc.data();

        if (isNewCertificateModel(certificateData)) {
          const referenceDocRef = certificateData.reference;
          const referenceDoc = await getDoc(referenceDocRef);

          tankData.fullActiveCert = referenceDoc.data();
        } else {
          tankData.fullActiveCert = certificateData;
        }
      }

      tankDataMap[dct.id] = tankData;
    });

    await Promise.all(tankPromises);

    return tankDataMap;
  };

  const fetchLastOperations = async () => {
    const tanksSnapshot = await getDocs(tanksRef);
    const tankIds = tanksSnapshot.docs.map((dct) => dct.id);
    const tankDataMap = await fetchTankData(tanksSnapshot);

    const lastOpsPromises = tankIds.map(async (tankId) => {
      const queryDocs = query(
        operationsRef,
        where('tankNumber', '==', tankId),
        orderBy('dateAndTime', 'desc'),
        limit(1),
      );
      const snapshot = await getDocs(queryDocs);
      return snapshot.empty ? null : snapshot.docs[0].data();
    });

    let lastOps = await Promise.all(lastOpsPromises);
    lastOps = lastOps.filter((op) => op !== null);

    return { lastOps, tankDataMap };
  };

  const generateXLSX = async (lastOpsAndTanks) => {

    const isRiviereDuMat = activeOrganization === 'RvoNkR1B3fw2kfzIZwtq' || 
    activeOrganization === 'mWEOVEIik3D7iIZ39ope';

    if (isRiviereDuMat) {
      return generateXLSXDRM(lastOpsAndTanks, globalState, t, cellarsRef);
    }

    const { lastOps, tankDataMap } = lastOpsAndTanks;
    const volumeUnit = lastOps[0]?.form.volumeUnit || 'hL';

    // First worksheet (inventory)
    const ws = XLSX.utils.json_to_sheet([]);

    await generateInventorySheet(ws, lastOps, tankDataMap, volumeUnit, globalState.extraOperationFields, t);

    // Filter today's operations
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const todayOps = lastOps.filter(op => {
      const opDate = new Date(op.dateAndTime.seconds * 1000 + op.dateAndTime.nanoseconds / 1000000);
      return opDate >= today;
    });

    // Second worksheet (today's operations)
    const ws2 = XLSX.utils.json_to_sheet([]);
    generateTodayOperationsSheet(ws2, todayOps, volumeUnit, t);

    // Third worksheet (cellar summaries)
    const ws3 = XLSX.utils.json_to_sheet([]);
    generateCellarSummarySheet(ws3, lastOps, volumeUnit, t);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, 'Dernières opérations par cuve');
    XLSX.utils.book_append_sheet(workbook, ws2, 'Dernières opérations du jour');
    XLSX.utils.book_append_sheet(workbook, ws3, 'Résumé par chai');

    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;

    XLSX.writeFile(workbook, `inventaire-${formattedDate}.xlsx`);
  };

  const handleClick = async () => {
    setLoading(true);
    try {
      const lastOpsAndTanks = await fetchLastOperations();
      generateXLSX(lastOpsAndTanks);
    } catch (err) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} : ${err.message}`, severity: 'error' });
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return (
    <LoadingButton
      variant="contained"
      loading={loading}
      onClick={handleClick}
      endIcon={<DownloadIcon />}
    >
      {t('daily_inventory.button')}
    </LoadingButton>
  );
}

