import {
  Box, IconButton, Tooltip, Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Timestamp,
} from 'firebase/firestore';
import RejectIcon from '@mui/icons-material/Cancel';

export default function NewCertificateItem({
  item, rejectedCerts, acceptedCerts, setRejectedCerts, setAcceptedCerts, onUpdateCellars,
}) {
  const { t } = useTranslation();
  const isRejected = rejectedCerts.some((cert) => cert.certificateId === item.certificateId);
  // eslint-disable-next-line no-underscore-dangle
  const timeStampFrom = (obj) => (obj instanceof Timestamp ? obj : new Timestamp(obj.seconds, obj.nanoseconds));

  const handleReject = () => {
    if (isRejected) return;
    setRejectedCerts((prevState) => [...prevState, item]);
    const newAcceptedCerts = acceptedCerts.filter((cert) => cert.certificateId !== item.certificateId);
    setAcceptedCerts(newAcceptedCerts);
    onUpdateCellars(item, false, newAcceptedCerts);
  };

  const handleAccept = () => {
    if (!isRejected) return;
    setRejectedCerts((prevState) => prevState.filter((cert) => cert.certificateId !== item.certificateId));
    const newAcceptedCerts = [...acceptedCerts, item];
    setAcceptedCerts(newAcceptedCerts);
    onUpdateCellars(item, true, newAcceptedCerts);
  };

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', gap: 1,
    }}
    >
      <Box sx={{
        display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 3, p: 0.5,
      }}
      >
        <Typography sx={{ textAlign: 'left' }}>
          {`${t('certificate')} : ${item.certificateName}`}
          <br />
          {`${t('valid_until')} : ${
            timeStampFrom(item.expirationDate).toDate().toLocaleDateString()}`}
          <br />
          {`${t('version')} : ${
            item.version}`}
        </Typography>
        <Typography sx={{ textAlign: 'right' }}>
          {`${t('tank')} : ${item.tankName}`}
          <br />
          {`${t('tank_selection.cellar')} : ${item.cellarName}`}
        </Typography>
        {!isRejected && (
          <Tooltip
            title={t('accept_certificates.reject')}
          >
            <IconButton onClick={handleReject} aria-label="reject">
              <RejectIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
}
